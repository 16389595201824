<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        class="mb-3 title" 
        disable-sort
        disable-pagination
        hide-default-footer
        loader-height="1"
        :loading="loading"
        :headers="headers" 
        :items="referenceIndices.data"
      >
        <template v-slot:item.resource="{ item }">
          {{ item.resource.name }}
        </template>

        <template v-slot:item.unit="{ item }">
          {{ item.reference_unit.name }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            dark
            label
            small
            color="primary"
            class="caption ttn"
            @click="edit(item)"
          >
            Edit
          </v-btn>
        </template>
      </v-data-table>

      <app-pagination
        v-if="referenceIndices.meta"
        :meta="referenceIndices.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      loading: true,
      headers: [
        { text: 'Resource', value: 'resource' },
        { text: 'Reference unit', value: 'unit' },
        { text: 'Value', value: 'value', },
        // { text: 'Actions', value: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      referenceIndices: 'getReferenceIndices'
    })
  },

  methods: {
    ...mapActions([
      'setReferenceIndices',
    ]),

    loadReferenceIndices () {
      this.setReferenceIndices({
        page: this.page
      }).then(() => {
        this.loading = false
      })
    },

    pageChanged (page) {
      this.page = page
      this.loading = true
      this.loadReferenceIndices()
    },
  },

  mounted () {
    this.loadReferenceIndices()
  }
}
</script>